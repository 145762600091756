.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(236 236 236 / 10%);
  top: 0;
  left: 0;
  z-index: 100;

  &__box {
    padding: 32px;
    position: relative;
    width: 646px;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background: #262626;
    box-shadow: 0px 0px 30px #FFF;
    border-radius: 20px;

    @include for-tablet {
      width: 90%;
    }

    @include for-small-phone {
      width: 95%;
    }
  }

  &__nft-id {
    font-weight: bold;
    font-size: 24px;
    line-height: 115%;
    color: $purpure-color;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  &__nft-id-white {
    font-weight: 400;
    color: $white;
  }

  &__close-btn {
    padding: 10px 18px;
    min-width: 120px;
    font-size: 24px;
    line-height: 115%;
    color: $white;
    border: 2px solid $purpure-color;
    border-radius: 100px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 0px $purpure-color;

    &:hover {
      box-shadow: 0px 0px 20px $purpure-color-hover;
    }

    @include for-small-phone {
      padding: 8px 10px;
      min-width: 100px;
    }
  }

  &__input {
    margin-bottom: 22px;
    width: 100%;
    height: 44px;
    padding-left: 15px;
    font-size: 18px;
  }

  &__controls {
    display: flex;
    gap: 32px;
    justify-content: flex-end;

    @include for-small-phone {
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__send-btn {
    padding: 10px 18px;
    min-width: 120px;
    font-size: 24px;
    line-height: 115%;
    background: $purpure-color;
    border-radius: 100px;
    color: $white;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 30px $purpure-color;

    &:hover {
      background-color: $purpure-color-hover;
      box-shadow: 0px 0px 40px $purpure-color-hover;
    }

    @include for-small-phone {
      padding: 8px 10px;
      min-width: 100px;
    }
  }

  // code for hide inputn[number] arrows

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
