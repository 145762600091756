// Colors HEX
$white: #ffffff;

$light-color: #fdf7fa;
$common-color: #262626;
$secondary-color: #64C5C8;
$purpure-color: #8c60bb;
$green-color: #4b8f8c;
$blue-color: #6b6ef9;
$red-color: #DC3D3E;
$light-red-color: #b5813e;

$red-color-hover: #dc3d3dc8;
$purpure-color-hover: #b26ffa;
$blue-color-hover: #8181f5;

// font
$common-font: 'Montserrat', sans-serif;

// size
$block-width: 992px; ;
