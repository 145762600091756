.reveal {
  background: rgba(226, 245, 255, 0.5);
  padding: 65px 20px 55px;

  &__list {
    display: flex;
    gap: 60px;
    flex-wrap: wrap;
  }

  &__data {
    width: $block-width;
    margin: auto;
  }

  &__image {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    width: 144px;
  }

  &__top {
    position: relative;
    margin-bottom: 20px;
    width: fit-content;

    &:hover {
      cursor: pointer;

      .reveal__image {
        opacity: 0.3;
      }
      .reveal__question-sign {
        opacity: 0.1;
      }

      .reveal__text-hover {
        opacity: 1;
      }
    }
  }

  &__icon {
    margin-right: 18px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &__question-sign {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
    line-height: 39px;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    color: $red-color;

    &-big {
      font-size: 36px;
      line-height: 54px;
      font-weight: 600;
    }
  }

  &__text-hover {
    position: absolute;
    left: 50%;
    top: 50%;
    color: $purpure-color;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 20px;
    line-height: 115%;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__question-grey {
    width: 18px;
    height: 18px;
    color: #c4c4c4;
    border: 3px solid #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
