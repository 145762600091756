.shareable-input {
  padding: 15px;
  background-color: $white;
  font-size: 14px;
  line-height: 19px;
  color: $common-color;
  border-radius: 10px;
  width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  border: none;
}
