.nft-item-info {
  background-color: #E5E5E5;
  padding: 17px;
  border-radius: 5px;
  display: flex;
  gap: 34px;
  pointer-events: none;
  font-size: 10px;
  line-height: 200%;
  width: 400px;

  &__icon-wrapper {
    position: relative;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 144px;
    height: 144px;
    overflow: hidden;
    border-radius: 100px;
  }
  &__image {
    width: 144px;
    height: auto;
  }

  &__rarity {
    position: absolute;
    right: -10px;
    top: 90px;
    background-color: $white;
    width: 28px;
    height: 28px;
    border-radius: 100px;
    line-height: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__number {
    margin-top: 26px;
    text-align: center;
  }

  &__stats {
    min-width: 100px;
    overflow: hidden;
  }
}
