.price {
  font-weight: 600;
  display: flex;
  align-items: center;
  background-color: $secondary-color;
  padding-left: 3px;

  &__near {
    font-size: 24px;
    line-height: 150%;
    color: $red-color;
  }

  &__count {
    font-size: 48px;
    line-height: 150%;
    color: $light-color;
  }
}
