.generate {
  display: flex;
  background-color:#fff;
  position: relative;
  z-index: 1;

  &__footer {
    font-size: 14px;
    color: $common-color;
  }
  
  &__container {
    width: 992px;
    margin: auto;
    padding: 170px 0 210px;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @include for-small-desktop {
      padding: 139px 20px 150px;
    }

    @include for-tablet {
      padding: 50px 20px 100px;
      flex-wrap: wrap;
      gap: 40px;
      width: 100%;
    }
  }

  &__information {
    max-width: 371px;
    color: $light-color;
    margin-top: 20px;

    @include for-tablet {
      max-width: unset;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    margin-bottom: 40px;
    color: $common-color;
  }
  
  &__text {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 24px;
    color: #2F9b87;
  }
  &__text2 {
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 24px;
    color: #2F9b87;
  }
  &__text-big {
    font-size: 24px;
    margin-bottom: 24px;
    color: $common-color;
  }

  &__buy-btn {
    width: 200px;
    height: 72px;
    display: block;
    margin-top: 40px;
    font-family: $common-font;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.01em;
    padding: 18px 40px;
    text-align: center;
    transition: all 0.3s ease-in-out;

    background: #CB015B;
    border-radius: 100px;
    filter: drop-shadow(0px 0px 10px #CB015B);
  }

  &__img {
    width: 500px;
    margin: 25px 30px 0 30px;

    @include for-laptop {
      width: 350px;
      margin-right: 0;
    }

    @include for-tablet {
      margin-top: 60px;
    }
    @include for-phone {
      width: 100%;
      margin-left: 0;
      padding-left: 10px;
      margin-top: 20px;
    }

    &-sold-out {
      @include for-tablet {
        padding-left: 0;
      }
    }
  }
}
