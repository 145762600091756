.hero {
  background-color: #DC3D3E;
  padding: 130px 0 259px;

  @include for-tablet {
    padding: 50px 0 100px;
  }

  @include for-phone {
    padding-top: 20px;
  }

  &__container {
    position: relative;
    color: #ffffff;
  }

  &__information {
    width: $block-width;
    margin: auto;
    position: relative;
    padding-bottom: 46px;

    @include for-laptop {
      padding: 0 30px 46px;
      width: 100%;
    }

    @include for-phone {
      padding: 0 20px 155px;
    }
  }

  &__title {
    font-size: 50px;
    line-height: 115%;
    margin-bottom: 24px;

    @include for-phone {
      font-size: 40px;
    }

    &_purpure {
      color: #ffffff;
    }
  }

  &__text {
    font-size: 22px;
    line-height: 150%;
    max-width: 475px;
    margin-bottom: 14px;
    color: #ffffff;

    @include for-phone {
      max-width: unset;
      font-size: 20px;
    }
  }

  &__calendar-btn {
    margin-top: 30px;
  }

  &__image {
    width: 486px;
    height: auto;
    position: absolute;
    z-index: 5;
    right: -70px;
    bottom: 70px;
    width: 573px;


    @include for-laptop {
      width: 300px;
      right: -30px;
    }

    @include for-tablet {
      width: 200px;
      right: 0px;
    }
    @include for-phone {
      width: 50%;
      margin: auto;
      bottom: -110px;
    }
  }

  &__headline__image {
    margin-bottom: 30px;

    @include for-tablet {
      width: 200px;
      right: 0px;
    }
    @include for-phone {
      width: 100%;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    
    &-ellipse {
      position: absolute;
      left: 50%;
      transform: translateX(-40%);
      pointer-events: unset;
    }

    &-cloud1 {
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-200px);
    }

    &-cloud2 {
      position: absolute;
      left: 50%;
      bottom: 78px;
      transform: translateX(-30px);
    }

    &-cloud3 {
      position: absolute;
      left: 50%;
      bottom: 78px;
      transform: translateX(380px);
    }
  }
}
