.rarity {
  display: flex;
  flex-direction: column;
  padding: 110px 0 110px;
  overflow: hidden;
  background-color: #81CBCD;
  

  @include for-tablet {
    padding: 50px 0 100px;
  }

  &__title {
    position: relative;
    z-index: 10;
    font-size: 34px;
    line-height: 115%;
    width: 330px;
    color: #fff;
    margin-bottom: 20px;

    @include for-phone {
      text-align: center;
    }

    @include for-small-phone {
      width: unset;
    }
  }

  &__subtitle {
    position: relative;
    z-index: 10;
    font-size: 24px;
    line-height: 115%;
    width: 330px;
    color: #fff;

    @include for-phone {
      text-align: center;
    }

    @include for-small-phone {
      width: unset;
    }
  }

  &__text {
    width: 312px;
    font-size: 18px;
    line-height: 150%;
    color: #fff;
    margin-bottom: 16px;
  }

  &__header {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-top: 20px;
    width: 992px;
    margin: auto;

    @include for-small-desktop {
      padding: 20px 20px 0;
    }

    @include for-laptop {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      width: 100%;
    }

    &-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      top: -30px;
      left: 100px;
    }
  }

  &__content-wrapper {
    position: relative;
    z-index: 10;
    width: 100%;
  }

  &__content {
    width: 992px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    position: relative;
    z-index: 10;

    @include for-small-desktop {
      padding: 40px 20px;
    }

    @include for-laptop {
      flex-direction: column-reverse;
      align-items: center;
      gap: 40px;
      width: 100%;
    }
  }
}
