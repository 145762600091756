.header {
  padding: 36px 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #DC3D3E;
  color: #FFF;

  
  @include for-small-desktop {
    padding: 36px;
    flex-wrap: wrap;
  }

  @include for-laptop {
    flex-direction: column;
  }

  @include for-tablet {
    padding: 20px;
    gap: 20px;
  }
  &logo{
    position:absolute;
    
  }
  &__navigation {
    flex-grow: 2;
    margin-right: 30px;

    @include for-tablet {
      margin-right: 0;
    }
  }

  &__social {
    margin-right: 106px;

    @include for-small-desktop {
      margin-right: 30px;
    }

    @include for-laptop {
      margin-right: 0;
    }

    @include for-phone {
      display: none !important;
    }
  }

  &__wallet {
    position: relative;
    display: flex;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #FFF;
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    font-size: 16px;
  }

  &__wallet:hover {
    .header__wallet-logout {
      top: 25px;
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }

  &__wallet-name {
    position: relative;
    cursor: pointer;
  }

  &__wallet-logout {
    position: absolute;
    cursor: pointer;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    color: black;
    font-weight: bold;
    
    border: 1px solid black;
    padding: 5px;
    border-radius: 15px;
    width: 50%;
  }
}
