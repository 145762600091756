.rarity-item {
  position: relative;

  &:last-child::after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
  
  }

  &__header {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__image {
    width: 117px;
  }
}
