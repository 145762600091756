.shareable-circle {
  border-radius: 100px;
  border: 2px solid $white;
  outline: 1px solid #969696;
  min-width: 20px;
  min-height: 20px;
  background-color: $white;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;

  &__active {
    background-color: #969696;
  }
}
