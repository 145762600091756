.rate-item {
  width: 147px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 5;

  &__border {
    border-right: #868585;
    border-right-width: 2px;
    border-right-style: solid;
  }

  &__text {
    color: #fff
  }

  &:not(:last-child)::after {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    right: -1px;
    opacity: 0.5;
    background: linear-gradient(
      to top,
      rgba(196, 196, 196, 1),
      rgba(196, 196, 196, 0)
    );
  }

  &__count {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 15px;
    color: #FFF;
  }

  &__icon {
    margin-bottom: 10px;
  }

  &__text {
    color: #fff;
    line-height: 150%;
    margin-bottom: 10px;
  }
}
