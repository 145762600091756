.faq {
  padding-top: 150px;
  position: relative;
  font-family: 'Manrope', sans-serif;
  background-color: #79CBE5;
  &__wrapper {
    
    @include for-tablet {
      padding-top: 50px;
    }
  }

  &__container {
    padding-bottom: 200px;
    position: relative;
    z-index: 10;
    width: 586px;
    margin: auto;

    @include for-tablet {
      padding: 0 20px 100px;
      width: 100%;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    color: #000;
    margin-bottom: 40px;
  }

  &__list {
    color: #000;
    line-height: 150%;
  }

  &__item-ol {
  }

  &__img {
    width: 330px;
    position: absolute;
    left: 600px;
    bottom: 0px;

    @include for-phone {
      display: none;
    }
  }

  &__item-ol > li {
    padding-bottom: 10px;
  }

  &__item {
    margin-bottom: 60px;

    &-title {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 14px;
    }

    &-team {
      margin-bottom: 16px;
    }

    &-text {
      font-size: 16px;
    }
  }
}
.faqbefore {
    padding-top: 150px;

  position: relative;
  font-family: 'Manrope', sans-serif;
  background-color: #79CBE5;
  &__wrapper {

    @include for-tablet {
      padding-top: 50px;
    }
  }

  &__container {
    padding-bottom: 200px;
    position: relative;
    z-index: 10;
    width: 586px;
    margin: auto;

    @include for-tablet {
      padding: 0 20px 100px;
      width: 100%;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    color: #3F4246;
    margin-bottom: 40px;
  }

  &__list {
    color: #000;
    line-height: 150%;
  }

  &__item {
    margin-bottom: 60px;

    &-title {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 14px;
    }

    &-team {
      margin-bottom: 16px;
    }
  }
}
