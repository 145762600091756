.learn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 130px 30px 170px;
  color: $secondary-color;
  width: 100%;
  margin: auto;
  padding-left: 20%;
  padding-right: 20%;

  background-color: #F7C6D0;
  @include for-laptop {
    width: 100%;
    padding: 130px 30px 170px;
  }

  @include for-tablet {
    padding: 50px 30px 100px;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include for-phone {
    padding: 50px 20px 100px;
  }

  &__title {
    font-size: 36px;
    line-height: 115%;
    margin-bottom: 40px;
    color: #DD3E3E;
  }

  &__title-img {
    margin-bottom: 40px;
  }

  &__text {
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 16px;
    max-width: 345px;
    color: $common-color;
  }

  &__picture {
    position: relative;

    &-image {
      max-width: 530px;
      width: 100%;

      @include for-tablet {
        max-width: 400px;
      }
    }
  }

  &__img {
    position: relative;
    z-index: 10;
    width: 537px;
    @include for-phone {
      width: 100%;
    }
  }

  &__background-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include for-phone {
      svg {
        width: 100%;
      }
    }
  }

  &__information {
  }

  &__background-ellipse {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    @include for-phone {
      width: 100%;

      svg {
        width: 100%;
      }
    }
  }
}
