.hide-mobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.connect-wallet {
  background: #DC3D3E;
  border: 2px solid #FFF;
  backdrop-filter: blur(32px);
  border-radius: 56px;
  transition: all 0.3s ease-in-out;
  color:#FFF;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 10px 4px #FFF ;
  }

  &__text {
    text-align: center;
    padding: 18px;
    height: 100%;
    letter-spacing: 0.01em;
    color: #FFF;
    font-family: $common-font;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    width: fit-content;
    min-width: 200px;

    &-name {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;

      @include for-tablet {
        max-width: 400px;
      }

      @include for-phone {
        max-width: 300px;
        padding: 13px;
      }

      @include for-small-phone {
        max-width: 200px;
      }

      &::after {
        content: 'Log Out';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        opacity: 0;
        font-size: 12px;
        transition: all 0.3s ease-in-out;
      }

      &:hover::after {
        opacity: 0.6;
      }
    }
  }
}
